import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useState } from 'react';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export default function ReactQueryProvider({
  children,
}: {
  children: ReactNode;
}) {
  // get_vehicle_makes.php
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 60 * 1000,
        },
      },
    }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={true} client={queryClient} /> */}

      {children}
    </QueryClientProvider>
  );
}
