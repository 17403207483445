import { create } from 'zustand';

export interface AlertState {
  alertOpen: boolean;
  setAlertOpen: (alertOpen: boolean) => void;
  alertContext: string | null;
  setAlertContext: (alertContext: string | null) => void;
  alertCallback: Function | null;
  setAlertCallback: (alertCallback: Function | null) => void;
  alertProps: any;
  setAlertProps: (alertProps: any) => void;
  alertTitle: string;
  setAlertTitle: (alertTitle: string) => void;
  alertDescription: string | null;
  setAlertDescription: (alertDescription: string | null) => void;
  alertConfirmText: string;
  setAlertConfirmText: (alertConfirmText: string) => void;
  alertVariant: string;
  setAlertVariant: (alertVariant: string) => void;
  showAlert: (config: ShowAlertConfig) => void;
  closeAlert: () => void;
  alertFormValues: { [key: string]: any };

  setAlertFormValues: (update: { [key: string]: any }) => void;
  alertShowCancel: boolean;
  setAlertShowCancel: (alertShowCancel: boolean) => void;
}

interface ShowAlertConfig {
  alertContext: string;
  alertProps?: any;
  alertTitle: string;
  alertDescription: string;
  alertConfirmText: string;
  alertVariant: 'default' | 'destructive';
  alertCallback?: Function;

  alertShowCancel: boolean;
}

export const useStoreAlert = create<AlertState>((set) => ({
  alertOpen: false,
  alertContext: null,
  alertProps: null,
  alertTitle: '',
  alertDescription: '',
  alertConfirmText: '',
  alertVariant: 'default',
  alertCallback: null,
  alertFormValues: {},
  alertShowCancel: true,
  setAlertShowCancel: (alertShowCancel) => set({ alertShowCancel }),

  // Function to show the alert
  showAlert: ({
    alertContext,
    alertProps,
    alertTitle,
    alertDescription,
    alertShowCancel,
    alertConfirmText,
    alertVariant,
    alertCallback,
  }: ShowAlertConfig) => {
    set({
      alertOpen: true,
      alertContext,
      alertProps,
      alertTitle,
      alertDescription,
      alertConfirmText,
      alertVariant,
      alertCallback: alertCallback || null,
      alertShowCancel,
    });
  },

  setAlertFormValues: (update: { [key: string]: any }) => {
    console.log(`Updating alertFormValues`, update);
    set((state) => ({
      alertFormValues: {
        ...state.alertFormValues,
        ...update,
      },
    }));
  },

  // Function to close the alert
  closeAlert: () => {
    set({
      alertOpen: false,
      alertContext: null,
      alertProps: null,
      alertTitle: '',
      alertDescription: '',
      alertConfirmText: '',
      alertVariant: 'default',
      alertCallback: null,
    });
  },

  // Setters for individual state properties
  setAlertOpen: (alertOpen: boolean) => set({ alertOpen }),
  setAlertContext: (alertContext: string | null) => set({ alertContext }),
  setAlertProps: (alertProps: any) => set({ alertProps }),
  setAlertTitle: (alertTitle: string) => set({ alertTitle }),
  setAlertDescription: (alertDescription: string | null) =>
    set({ alertDescription }),
  setAlertConfirmText: (alertConfirmText: string) => set({ alertConfirmText }),
  setAlertVariant: (alertVariant: string) => set({ alertVariant }),
  setAlertCallback: (alertCallback: Function | null) => set({ alertCallback }),
}));
