// autoviz-component-monorepo/packages/product-catalog/src/components/alert-dialog-component.tsx
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './ui/alert-dialog';

import { cn } from '@/lib/utils';
import React from 'react';
import { useStoreAlert } from '../lib/store.alert';
import { buttonVariants } from './ui/button';

const AlertDialogComponent: React.FC = () => {
  const {
    alertOpen,
    setAlertOpen,
    alertProps,
    alertCallback,
    alertTitle,
    alertDescription,
    alertConfirmText,
    alertVariant,
    alertFormValues,
    alertShowCancel,
  } = useStoreAlert((state) => state);

  const handleCancel = () => {
    setAlertOpen(false);
  };

  const handleConfirm = () => {
    setAlertOpen(false);
    if (alertCallback) alertCallback({ alertProps, ...alertFormValues });
  };
return (
  <AlertDialog open={alertOpen}>
    <AlertDialogContent className={`z-[99999999999]`}>
      <AlertDialogHeader>
        <AlertDialogTitle>{alertTitle}</AlertDialogTitle>
        <AlertDialogDescription>{alertDescription}</AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        {alertShowCancel !== false && (
          <AlertDialogCancel onClick={() => handleCancel()}>
            Cancel
          </AlertDialogCancel>
        )}
        <AlertDialogAction
          onClick={() => handleConfirm()}
          className={cn(buttonVariants({ variant: alertVariant as any }))}
        >
          {alertConfirmText}
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
);
};

export default AlertDialogComponent;

// const alertOpen = useStoreAlert((state) => state.alertOpen);
// const setAlertOpen = useStoreAlert((state) => state.setAlertOpen);
// const alertProps = useStoreAlert((state) => state.alertProps);
// const alertCallback = useStoreAlert((state) => state.alertCallback);
// const [title, setTitle] = useState('Please add a name for your design');
// const [description, setDescription] = useState<null | string | JSX.Element>(
//   null,
// );
// const [actionText, setActionText] = useState('Save');
// const [variant, setVariant] = useState<any>('default');
