import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import React from 'react';

import { useStore } from '@/lib/store';
import QRCode from 'react-qr-code';

const QrCodeModal: React.FC<any> = ({
  classNameButton = '',
  openCallback,
  closeCallback,
}: {
  classNameButton?: string;
  openCallback?: () => void;
  closeCallback?: () => void;
} = {}) => {
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );

  const handleOpenChange = (open: boolean) => {
    setQrCodeOpen(open);
  };

  const qrCodeOpen = useStore((state) => state.qrCodeOpen);
  const qrCodeTitle = useStore((state) => state.qrCodeTitle);
  const qrCodeSubTitle = useStore((state) => state.qrCodeSubTitle);
  const setQrCodeOpen = useStore((state) => state.setQrCodeOpen);
  const qrCodeValue = useStore((state) => state.qrCodeValue);

  const location = qrCodeValue || window.location.href;
  return (
    <>
      <Dialog open={qrCodeOpen} onOpenChange={handleOpenChange}>
        <DialogContent
          className={`z-[999999999999] bg-card p-12 max-w-[320px] w-[75%] rounded-md h-auto`}
        >
          <DialogHeader>
            <DialogTitle
              className={`mb-3 text-foreground text-xl font-bold mx-auto`}
            >
              {qrCodeTitle}
            </DialogTitle>
            <div className={`text-center`}>{qrCodeSubTitle}</div>
            <a href={location} target="_blank">
              <QRCode
                level="Q"
                size={128}
                className={`py-2`}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={location}
                viewBox={`0 0 320 320`}
              />
            </a>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QrCodeModal;
