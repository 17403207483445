import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import packageJson from '../package.json';
import AlertDialogComponent from './components/alert-dialog-component';
import DynamicComponentLoader from './components/dynamic-component-loader';
import { Toaster } from './components/ui/toaster';
import './globals.css';
import ReactQueryProvider from './providers/react-query-provider';
import QrCodeModal from './components/qr-code-modal';
import ModelViewer from './components/model-viewer';
import { ModelViewerProvider } from './hooks/useModelViewer.Provider';

// Create routes
const routes = createRoutesFromElements(
  <>
    <Route
      path="/"
      element={<DynamicComponentLoader componentNameAsProp="home" />}
    />

    <Route
      path="3dviewer"
      element={<DynamicComponentLoader componentNameAsProp="3d-viewer" />}
    />

    <Route
      path="ar"
      element={<DynamicComponentLoader componentNameAsProp="ar-launcher" />}
    />

    <Route
      path="catalog"
      element={<DynamicComponentLoader componentNameAsProp="product-catalog" />}
    >
      <Route
        path="wheel/:styleId/:wheelId?"
        element={
          <DynamicComponentLoader componentNameAsProp="product-detail" />
        }
      />
    </Route>
    <Route
      path="configurator/:styleId?/:wheelId?"
      element={
        <DynamicComponentLoader componentNameAsProp="wheel-configurator" />
      }
    />
  </>,
);

// Pass the basename directly to the router
const router = createBrowserRouter(routes, {
  basename: '/', // Replace '/my-base-path' with your actual base path
});
const root = ReactDOM.createRoot(
  document.getElementById('autoviz_product_catalog_root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ReactQueryProvider>
      <RouterProvider router={router} />
      <div className={`h-[calc(80dvh-100px)] w-full`}>
        <QrCodeModal />
      </div>
      <AlertDialogComponent />
      <Toaster />
      <div
        className={`fixed bottom-0 right-1 text-[8px] z-[99999999] safe-padding text-[#88888888] text-center`}
      >
        v{packageJson.version}
      </div>
    </ReactQueryProvider>
  </React.StrictMode>,
);
