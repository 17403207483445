// autoviz-component-monorepo/packages/product-catalog/src/components/ui/button.tsx

import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-2xl text-sm font-medium transition-all focus-visible:outline-none  focus-visible:ring-primary disabled:pointer-events-none disabled:opacity-50 active:opacity-60 active:scale-95 border-1 border-transparent !hover:bg-opacity-50 relative',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground shadow-lg',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-primary text-primary bg-none shadow-sm hover:bg-accent hover:text-accent-foreground dark:border-primary-foreground dark:text-primary-foreground',
        secondary:
          'bg-white/90 text-secondary-foreground shadow-sm dark:bg-[#27272add]',
        revertedButton:
          'text-base !text-primary-foreground bg-black dark:bg-white dark:!text-black',
        muted: 'bg-muted text-muted-foreground shadow-md hover:bg-muted/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-6 text-lg',
        xs: 'h-6 rounded-2xl px-2 text-xs',
        sm: 'h-8 rounded-2xl px-3 text-xs',
        lg: 'h-10 rounded-2xl px-8',
        xl: 'h-14 px-8 py-10 text-xl w-full',
        chip: 'px-4 py-3 text-base rounded-xl ',
        chipSm: 'px-3 py-1.5 text-base rounded-md',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, loading = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        aria-label="Nav Button"
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      >
        {/* {loading === true ? (
          <Loader visible={true} className={` opacity-60 `} />
        ) : ( */}
        {props.children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
