// autoviz-component-monorepo/packages/product-catalog/src/lib/utils.ts
import { useMediaQuery } from '@react-hook/media-query';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AutovizDbWheelModel, WheelStyle } from './types';
import {
  isMobile as ReactDeviceDetect_isMobile,
  isTablet as ReactDeviceDetect_isTablet,
} from 'react-device-detect';

export const devBorderWidth = '0px';
export const siteRoot =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost.autoviz.io:8087'
    : 'https://autoviz.io';
export const siteRootPortal =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost.autoviz.io:8087/portal'
    : 'https://autoviz.io/portal';

export const ENVIRONMENT_IMAGE = '/images/photo_studio_blocks_1k.hdr';
export const IOS_USDZ = '/images/27417_1728333331.usdz';

export const useMediaQueries = () => {
  const isMobileViewPort = useMediaQuery('only screen and (max-width: 768px)');
  const isTabletViewPort = useMediaQuery(
    'only screen and (min-width: 769px) and (max-width: 1024px)',
  );
  const isDesktopViewPort = useMediaQuery(
    'only screen and (min-width: 1025px)',
  );
  return { isMobileViewPort, isTabletViewPort, isDesktopViewPort };
};

export const useUserAgentQueries = () => {
  const RealMobile_isTablet = isRealMobile(); //@ia use Daniel's solution which addresses iPad user agent detection bug
  return {
    isMobileUserAgent: ReactDeviceDetect_isMobile,
    isTabletUserAgent: RealMobile_isTablet,
    isDesktopUserAgent: ReactDeviceDetect_isTablet,
  };
};

export const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT || `${siteRoot}/api`;
export const portalUploadsUrl =
  process.env.REACT_APP_PORTAL_UPLOADS_URL || `${siteRootPortal}/uploads`;

export const corsAssetLoaderUrl = `${siteRoot}/load/?path=`;
export const productCatalogPath = `/catalog/wheel`;
export const configuratorPath = `/configurator`;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const scrollToTop = () => {
  document
    .getElementById('scroll-area-viewport-target')
    ?.scrollTo({ top: 0, behavior: 'smooth' });
};

export const disableRightClick = (
  event: React.MouseEvent<HTMLImageElement>,
) => {
  event.preventDefault();
};

export const filterInMemoryProducts = ({
  filters,
  products,
}: {
  filters: any;
  products: any;
}) => {
  console.log(`filters`, filters);
  return products.filter((product: any) => {
    return Object.keys(filters).every((key) => {
      if (filters[key].value) {
        let c1 = product?.wheel_info?.WheelStyles?.[0]?.[key];
        let c2 = filters[key].value;
        console.log(`c`, key, product?.wheel_info?.WheelStyles?.[0], c1, c2);
        return c1 === c2;
      }
      return true;
    });
  });
};

export const imgSrcFromWheelModel = (
  currentAutovizDbWheelModel?:
    | AutovizDbWheelModel
    | WheelStyle
    | undefined
    | null,
) =>
  `${currentAutovizDbWheelModel?.wheel_info?.ImgUrlBase}${currentAutovizDbWheelModel?.wheel_info?.WheelStyles[0].Img0001}` ||
  ''; //@ia add placeholder

export const pause = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isRealMobile = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  // console.log(`userAgent`, userAgent);
  var ret =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent,
    );
  // if (!ret)
  //     return $(window).width() < 768;
  return ret || iOS();
};
function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const hexToRgb = (hex: string): [number, number, number, number] => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the hex string and get RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Convert RGB to values between 0 and 1, and set alpha to 1
  return [r / 255, g / 255, b / 255, 1];
};

export const rgbToHex = (rgba: [number, number, number, number?]): string => {
  const [r, g, b, a] = rgba;

  // Convert each component to a two-digit hex value
  const redHex = Math.round(r * 255)
    .toString(16)
    .padStart(2, '0');
  const greenHex = Math.round(g * 255)
    .toString(16)
    .padStart(2, '0');
  const blueHex = Math.round(b * 255)
    .toString(16)
    .padStart(2, '0');

  // If alpha is provided, include it in the hex; otherwise, return RGB hex only
  if (a !== undefined) {
    const alphaHex = Math.round(a * 255)
      .toString(16)
      .padStart(2, '0');
    return `#${redHex}${greenHex}${blueHex}${alphaHex}`;
  }

  return `#${redHex}${greenHex}${blueHex}`;
};

export const isNumeric = (value?: any): boolean => {
  return typeof value === 'number' && !isNaN(value);
};

export const DEFAULT_CONFIGURATOR_WHEEL_PATH = '/20272/585699?ref=demo';

export const DEFAULT_DEMO_MODEL_SRC =
  'https://storage.googleapis.com/avwheel3d/28434_1729897799.glb';
