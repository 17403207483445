// product-catalog/src/lib/store.ts

import { create } from 'zustand';
import
  {
    AutovizDbWheelModel,
    Brand,
    DomainResult,
    FetchWheelStylesResponse,
    ListDisplayMode,
    WheelStyle,
    WheelStyleDetail,
  } from './types';
import { productCatalogPath } from './utils';
import { SwatchType } from '@/components/wheel-configurator/wheel-configurator-helpers';

export interface CatalogState {
  //DISPLAY
  curPage: number;
  setCurPage: (curPage: number) => void;
  moreItems: boolean;
  setMoreItems: (moreItems: boolean) => void;
  listDisplayMode: ListDisplayMode;
  setListDisplayMode: (listDisplayMode: ListDisplayMode) => void;
  carouselResizeCount: number;
  setCarouselResizeCount: (count: number) => void;
  carouselInitialized: boolean;
  setCarouselInitialized: (initialized: boolean) => void;
  appInitialized: boolean;
  setAppInitialized: (initialized: boolean) => void;
  searchParams: Record<string, any>;
  setSearchParams: (searchParams: Record<string, any>) => void;
  fetchFromAutovizDb: boolean;
  setFetchFromAutovizDb: (fetchFromAutovizDb: boolean) => void;
  isThreeColumnView: boolean;
  setIsThreeColumnView: (isThreeColumnView: boolean) => void;
  modelViewerScriptsLoaded: boolean;
  setModelViewerScriptsLoaded: (modelViewerScriptsLoaded: boolean) => void;
  navigationContextRootPath: string;
  setNavigationContextRootPath: (navigationContextRootPath: string) => void;
  qrCodeValue: any;
  setQrCodeValue: (qrCodeValue: any) => void;
  qrCodeOpen: boolean;
  setQrCodeOpen: (qrCodeOpen: boolean) => void;
  qrCodeTitle: string;
  setQrCodeTitle: (qrCodeTitle: string) => void;
  qrCodeSubTitle: string;
  setQrCodeSubTitle: (qrCodeSubTitle: string) => void;
  outletLoaderIsVisible: boolean;
  setOutletLoaderIsVisible: (outletLoaderIsVisible: boolean) => void;

  //BRANDS
  brandsLoading: boolean;
  setBrandsLoading: (loading: boolean) => void;
  brands: Record<string, Brand>;
  setBrands: (brands: Record<string, Brand>) => void;
  currentBrand: Brand | null;
  setCurrentBrand: (currentBrand: Brand | null) => void;
  brandLogosUrlBase: string;
  setBrandLogosUrlBase: (brandLogosUrlBase: string) => void;
  currentBrandIds: string | null;
  setCurrentBrandIds: (currentBrandIds: string | null) => void;

  //PRODUCTS
  products: WheelStyle[];
  styleId: string;
  setStyleId: (styleId: string) => void;
  wheelId: string;
  setWheelId: (wheelId: string) => void;
  setProducts: (
    products: WheelStyle[] | ((prevProducts: WheelStyle[]) => WheelStyle[]),
  ) => void;
  imgUrlBase: string;
  setImgUrlBase: (imgUrlBase: string) => void;
  productsLoading: boolean;
  setProductsLoading: (loading: boolean) => void;
  productsLoadingTrigger: string | null;
  setProductsLoadingTrigger: (trigger: string) => void;
  currentDomain: DomainResult | null;
  setCurrentDomain: (currentDomain: DomainResult | null) => void;
  inMemoryProducts: WheelStyle[];
  setInMemoryProducts: (inMemoryProducts: WheelStyle[]) => void;
  isLeftDrawerOpen: boolean;
  setIsLeftDrawerOpen: (isLeftDrawerOpen: boolean) => void;
  currentAutosyncApiWheelStyle: WheelStyleDetail | null;
  setCurrentAutosyncApiWheelStyle: (
    currentAutosyncApiWheelStyle: WheelStyleDetail | null,
  ) => void;
  currentAutosyncApiWheelStyles: WheelStyleDetail[] | [];
  setCurrentAutosyncApiWheelStyles: (
    currentAutosyncApiWheelStyles: WheelStyleDetail[],
  ) => void;
  currentAutovizDbWheelModel: AutovizDbWheelModel | null;
  setCurrentAutovizDbWheelModel: (currentAutovizDbWheelModel: any) => void;
  currentAutovizDbWheelInfo: FetchWheelStylesResponse | null;
  setCurrentAutovizDbWheelInfo: (
    currentAutovizDbWheelInfo: FetchWheelStylesResponse | null,
  ) => void;

  // Model Viewer
  colors: SwatchType[];
  setColors: (colors: SwatchType[]) => void;
  finishes: SwatchType[];
  setFinishes: (finishes: SwatchType[]) => void;

  colorPickerHex: string;
  setColorPickerHex: (colorPickerHex: string) => void;
  colorPickerBaseColorFactor: [number, number, number, number];
  setColorPickerBaseColorFactor: (
    colorPickerBaseColorFactor: [number, number, number, number],
  ) => void;

  activeDataObject: any;
  setActiveDataObject: (activeDataObject: any) => void;
  defaultDataObject: any;
  setDefaultDataObject: (activeDataObject: any) => void;
  dynamicDataObject: any;
  setDynamicDataObject: (dynamicDataObject: any) => void;
  usingDefaultDataObject: boolean;
  setUsingDefaultDataObject: (usingDefaultDataObject: boolean) => void;

  urlModelState: string[];
  pushToUrlModelState: (value: string) => void;
  currentUrlModelStateIndex: number;
  setCurrentUrlModelStateIndex: (value: number) => void;

  currentPartName: string;
  setCurrentPartName: (currentPartName: string) => void;
  fullyInitialized: boolean;
  setFullyInitialized: (fullyInitialized: boolean) => void;
  savedDesigns: any[];
  setSavedDesigns: (savedDesigns: any[]) => void;
  currentDesignName: string;
  setCurrentDesignName: (currentDesignName: string) => void;
  modelViewerRefState: any;
  setModelViewerRefState: (modelViewerRefState: any) => void;
  modelViewerRefSelf: any;
  setModelViewerRefSelf: (modelViewerRefSelf: any) => void;
  arActivated: boolean;
  setArActivated: (arActivated: boolean) => void;
  arNeedsActivation: boolean;
  setArNeedsActivation: (arNeedsActivation: boolean) => void;
  show3DModel: boolean;
  setShow3DModel: (show3DModel: boolean) => void;
  arIsPresenting: boolean;
  setArIsPresenting: (arIsPresenting: boolean) => void;
  modelSrc: string;
  setModelSrc: (modelSrc: string) => void;
  loaderStateBump: number;
  setLoaderStateBump: (loaderStateBump: number) => void;
}

export const useStore = create<CatalogState>((set) => {
  return {
    // DISPLAY
    outletLoaderIsVisible: false,
    setOutletLoaderIsVisible: (outletLoaderIsVisible) =>
      set({ outletLoaderIsVisible }),

    // Model Viewer
    colors: [],
    setColors: (colors) => set({ colors }),
    finishes: [],
    setFinishes: (finishes) => set({ finishes }),
    colorPickerHex: '#727272',
    setColorPickerHex: (colorPickerHex) => set({ colorPickerHex }),
    colorPickerBaseColorFactor: [1, 1, 1, 1],
    setColorPickerBaseColorFactor: (colorPickerBaseColorFactor) =>
      set({ colorPickerBaseColorFactor }),

    urlModelState: [],
    pushToUrlModelState: (value) =>
      set((state) => {
        // Check if the last element in urlModelState is identical to the new value
        if (state.urlModelState[state.urlModelState.length - 1] === value) {
          return state; // No change if value is a duplicate of the last entry
        }
        return {
          urlModelState: [...state.urlModelState, value],
        };
      }),
    currentUrlModelStateIndex: 0,

    setCurrentUrlModelStateIndex: (value) =>
      set({ currentUrlModelStateIndex: value }),

    modelViewerRefState: null,
    setModelViewerRefState: (modelViewerRefState) =>
      set({ modelViewerRefState }),
    modelViewerRefSelf: null,
    setModelViewerRefSelf: (modelViewerRefSelf) => set({ modelViewerRefSelf }),
    arActivated: false,
    setArActivated: (arActivated) => set({ arActivated }),
    arNeedsActivation: false,
    setArNeedsActivation: (arNeedsActivation) => set({ arNeedsActivation }),
    show3DModel: false,
    setShow3DModel: (show3DModel) => set({ show3DModel }),
    arIsPresenting: false,
    setArIsPresenting: (arIsPresenting) => set({ arIsPresenting }),
    modelSrc: '',
    setModelSrc: (modelSrc) => set({ modelSrc }),
    loaderStateBump: 0,
    setLoaderStateBump: (loaderStateBump) => set({ loaderStateBump }),
    currentDesignName: '',
    setCurrentDesignName: (currentDesignName) => set({ currentDesignName }),
    savedDesigns: [],
    setSavedDesigns: (savedDesigns) => set({ savedDesigns }),
    fullyInitialized: false,
    setFullyInitialized: (fullyInitialized) => set({ fullyInitialized }),
    currentPartName: '',
    setCurrentPartName: (currentPartName) => set({ currentPartName }),
    activeDataObject: null,
    setActiveDataObject: (activeDataObject: any) => set({ activeDataObject }),
    defaultDataObject: null,
    setDefaultDataObject: (defaultDataObject: any) =>
      set({ defaultDataObject }),
    dynamicDataObject: null,
    setDynamicDataObject: (dynamicDataObject: any) =>
      set({ dynamicDataObject }),
    usingDefaultDataObject: true,
    setUsingDefaultDataObject: (usingDefaultDataObject: boolean) =>
      set({ usingDefaultDataObject }),

    //PRODUCTS
    styleId: '',
    setStyleId: (styleId) => set({ styleId }),
    wheelId: '',
    setWheelId: (wheelId) => set({ wheelId }),

    //QR CODE
    qrCodeValue: null,
    setQrCodeValue: (qrCodeValue) => set({ qrCodeValue }),
    qrCodeOpen: false,
    setQrCodeOpen: (qrCodeOpen) => set({ qrCodeOpen }),
    qrCodeTitle: '',
    setQrCodeTitle: (qrCodeTitle) => set({ qrCodeTitle, qrCodeSubTitle: '' }),
    qrCodeSubTitle: '',
    setQrCodeSubTitle: (qrCodeSubTitle) => set({ qrCodeSubTitle }),

    navigationContextRootPath: productCatalogPath,
    setNavigationContextRootPath: (navigationContextRootPath) =>
      set({ navigationContextRootPath }),

    modelViewerScriptsLoaded: false,
    setModelViewerScriptsLoaded: (modelViewerScriptsLoaded) =>
      set({ modelViewerScriptsLoaded }),

    currentAutosyncApiWheelStyle: null,
    setCurrentAutosyncApiWheelStyle: (currentAutosyncApiWheelStyle) =>
      set({ currentAutosyncApiWheelStyle }),

    currentAutosyncApiWheelStyles: [],
    setCurrentAutosyncApiWheelStyles: (currentAutosyncApiWheelStyles) =>
      set({ currentAutosyncApiWheelStyles }),

    currentAutovizDbWheelModel: null,
    setCurrentAutovizDbWheelModel: (currentAutovizDbWheelModel) =>
      set({ currentAutovizDbWheelModel }),

    currentAutovizDbWheelInfo: null,
    setCurrentAutovizDbWheelInfo: (currentAutovizDbWheelInfo) =>
      set({ currentAutovizDbWheelInfo }),

    isLeftDrawerOpen: false,
    setIsLeftDrawerOpen: (isLeftDrawerOpen) => set({ isLeftDrawerOpen }),
    isThreeColumnView: false,
    setIsThreeColumnView: (isThreeColumnView) => set({ isThreeColumnView }),
    brands: {},
    setBrands: (brands) => set({ brands }),
    currentBrand: null,
    setCurrentBrand: (currentBrand) => set({ currentBrand }),
    brandLogosUrlBase: '',
    setBrandLogosUrlBase: (brandLogosUrlBase) => set({ brandLogosUrlBase }),
    imgUrlBase: '',
    setImgUrlBase: (imgUrlBase) => set({ imgUrlBase }),
    curPage: 1,
    setCurPage: (curPage) => set({ curPage }),
    moreItems: true,
    setMoreItems: (moreItems) => {
      set({ moreItems });
    },
    products: [],
    setProducts: (
      products: WheelStyle[] | ((prevProducts: WheelStyle[]) => WheelStyle[]),
    ) =>
      set((state) => ({
        products:
          typeof products === 'function' ? products(state.products) : products,
      })),
    listDisplayMode: 'GRID',
    setListDisplayMode: (listDisplayMode) => set({ listDisplayMode }),
    carouselResizeCount: 0,
    setCarouselResizeCount: (count) => set({ carouselResizeCount: count }),
    carouselInitialized: false,
    setCarouselInitialized: (initialized) =>
      set({ carouselInitialized: initialized }),
    appInitialized: false,
    setAppInitialized: (initialized) => set({ appInitialized: initialized }),
    brandsLoading: false,
    setBrandsLoading: (loading) => set({ brandsLoading: loading }),
    productsLoading: false,
    setProductsLoading: (loading) => set({ productsLoading: loading }),
    productsLoadingTrigger: null,
    setProductsLoadingTrigger: (trigger) =>
      set({ productsLoadingTrigger: trigger }),
    currentDomain: null,
    setCurrentDomain: (currentDomain) => set({ currentDomain }),
    currentBrandIds: null,
    setCurrentBrandIds: (currentBrandIds) => set({ currentBrandIds }),
    searchParams: {},
    setSearchParams: (searchParams) => set({ searchParams }),
    fetchFromAutovizDb: true,
    setFetchFromAutovizDb: (fetchFromAutovizDb) => set({ fetchFromAutovizDb }),
    inMemoryProducts: [],
    setInMemoryProducts: (inMemoryProducts) => set({ inMemoryProducts }),
  };
});

interface FilterMenuState {
  filteredProducts: WheelStyle[];
  setFilteredProducts: (filteredProducts: WheelStyle[]) => void;
  filterData: any;
  setFilterData: (filterData: any) => any;
  openSection: string | null;
  setOpenSection: (section: string | null) => void;
  filterMenuOpen: boolean;
  setFilterMenuOpen: (filterMenuOpen: boolean) => void;
  sections: {
    [key: string]: any;
  };
  setSections: (sections: any) => void;
  setFilterItemState: ({
    section,
    displayName,
    value,
  }: {
    section: string;
    displayName: string;
    value: any;
  }) => void;
  removeFilterItemState: ({ section }: { section: string }) => void;
  resetSections: () => void;
  filtersCount: number;
  resetFilterData: () => void;
  setFiltersCount: (filtersCount: number) => void;
}

export const useFilterMenuStore = create<FilterMenuState>((set) => ({
  filteredProducts: [],
  setFilteredProducts: (filteredProducts) => set({ filteredProducts }),

  filterData: {},
  setFilterData: (filterData) => set({ filterData }),
  resetFilterData: () => set({ filterData: {} }),
  filtersCount: 0,
  setFiltersCount: (filtersCount: number) => set({ filtersCount }),

  openSection: null,
  setOpenSection: (section) => set({ openSection: section }),

  filterMenuOpen: false,
  setFilterMenuOpen: (filterMenuOpen) => set({ filterMenuOpen }),

  sections: {},

  setSections: (sections) => set({ sections }),
  setFilterItemState: ({ section, displayName, value }) =>
    set((state) => ({
      sections: {
        ...state.sections,
        [section]: {
          ...state.sections[section],
          displayName,
          value,
        },
      },
    })),

  removeFilterItemState: ({ section }) =>
    set((state) => {
      const newSections = { ...state.sections };
      delete newSections[section];
      return { sections: newSections };
    }),

  resetSections: () =>
    set(() => ({
      sections: {},
      openSection: null,
    })),
}));

interface CompanyState {
  companyDisplayName: string;
  setCompanyDisplayName: (companyDisplayName: string) => void;
  domainName: string;
  setDomainName: (domainName: string) => void;
  displayName: string;
  setDisplayName: (displayName: string) => void;
  logoUrl: string;
  setLogoUrl: (logoUrl: string) => void;
  gaKey: string;
  setGaKey: (gaKey: string) => void;
  primaryColor: string;
  setPrimaryColor: (primaryColor: string) => void;
}
export const useCompanyStore = create<CompanyState>((set) => ({
  companyDisplayName: '',
  setCompanyDisplayName: (companyDisplayName) => set({ companyDisplayName }),
  domainName: '',
  setDomainName: (domainName) => set({ domainName }),
  displayName: '',
  setDisplayName: (displayName) => set({ displayName }),
  logoUrl: '',
  setLogoUrl: (logoUrl) => set({ logoUrl }),
  gaKey: '',
  setGaKey: (gaKey) => set({ gaKey }),
  primaryColor: '',
  setPrimaryColor: (primaryColor) => set({ primaryColor }),
}));

interface SearchState {
  searchLoading: boolean;
  setSearchLoading: (loading: boolean) => void;
  searchResults: WheelStyle[];
  setSearchResults: (results: WheelStyle[]) => void;
}
export const useSearchStore = create<SearchState>((set) => ({
  searchLoading: false,
  setSearchLoading: (loading) => set({ searchLoading: loading }),
  searchResults: [],
  setSearchResults: (results) => set({ searchResults: results }),
}));


const getStoredThemePreference = (): boolean | null => {
  const storedTheme = localStorage.getItem('theme');
  try {
    return storedTheme ? JSON.parse(storedTheme) : null;
  } catch (error) {
    return null;
  }
};

const getSystemDefaultTheme = (): boolean => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return true;
  }
  return false;
};

// Function to retrieve the stored theme preference or fallback to the system default
const getInitialTheme = (urlSearchParams: URLSearchParams): boolean => {
  // Check if the theme query parameter exists and is valid
  const themeParam = urlSearchParams.get('theme');
  if (themeParam === 'light') return false; // false means light theme
  if (themeParam === 'dark') return true; // true means dark theme

  // Fallback to stored preference or system default
  const storedPreference = getStoredThemePreference();
  return storedPreference !== null ? storedPreference : getSystemDefaultTheme();
};

// Define the ThemeState interface
interface ThemeState {
  theme: 'light' | 'dark' | null;
  isDark: boolean;
  themeButton: boolean; // New variable to hold the themeButton state
  toggleTheme: () => void;
  setTheme: (theme: 'light' | 'dark') => void;
  setThemeButton: (value: boolean) => void; // Function to set themeButton
}

// Create the store
export const useThemeStore = create<ThemeState>((set) => {
  const urlSearchParams = new URLSearchParams(window.location.search); // Get current URL params
  const initialIsDark = getInitialTheme(urlSearchParams);
  const initialThemeButton = urlSearchParams.get('themeButton') !== 'false'; // Check themeButton param

  return {
    theme: initialIsDark ? 'dark' : 'light', // Initialize theme based on initial retrieval
    isDark: initialIsDark,
    themeButton: initialThemeButton, // Initialize themeButton
    toggleTheme: () =>
      set((state) => {
        const newTheme = !state.isDark;
        const themeString = newTheme ? 'dark' : 'light';
        localStorage.setItem('theme', JSON.stringify(newTheme));
        return { isDark: newTheme, theme: themeString };
      }),
    setTheme: (theme) =>
      set(() => {
        const isDark = theme === 'dark';
        localStorage.setItem('theme', JSON.stringify(isDark));
        return { isDark, theme };
      }),
    setThemeButton: (value) => set(() => ({ themeButton: value })), // Set themeButton state
  };
});



interface IVehicleSearch {
  // Stepper map [1 - 5]
  step: number;
  // functions to handle steppers ends
  setStep: (value: number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
  // functions to handle steppers ends

  // handle vehicle Make
  vehicleMake: string | null;
  SetVehicleMake: (value: string) => void;

  // handle Vehicle Year
  vehicleYear: string | number | null;
  SetVehicleYear: (value: string | number | null) => void;

  // handle Vehicle type
  vehicleType: string | null;
  SetVehicleType: (value: string) => void;

  // handle Vehicle model
  vehicleModel: string | null;
  SetVehicleModel: (value: string) => void;

  // handle Vehicle Trim
  vehicleTrim: {
    id: string | number;
    colorId: string | number;
  } | null;
  SetVehicleTrim: (value: {
    id: string | number;
    colorId: string | number;
  }) => void;

  // handle Vehicle Trim
  vehicleSize: string | number | null;
  SetVehicleSize: (value: string | number) => void;
}

// MAXSTEP

const MAX_STEP = 5;

export const useVehicleSearch = create<IVehicleSearch>((set) => ({
  step: 0,
  // functions to handle steppers ends
  decrementStep: () =>
    set((state) => {
      let decrementedStep = state.step - 1;
      return { step: decrementedStep <= 0 ? 0 : decrementedStep };
    }),
  incrementStep: () =>
    set((state) => {
      let IncrementedStep = state.step + 1;
      return { step: IncrementedStep >= MAX_STEP ? MAX_STEP : IncrementedStep };
    }),
  setStep: (value) =>
    set((state) => {
      return { step: value };
    }),
  // functions to handle steppers ends
  vehicleMake: null,
  SetVehicleMake: (value) =>
    set((state) => {
      return { vehicleMake: value, step: state.step + 1 };
    }),

  vehicleYear: null,
  SetVehicleYear: (value) =>
    set((state) => {
      return { vehicleYear: value, step: state.step + 1 };
    }),

  vehicleModel: null,
  SetVehicleModel: (value) =>
    set((state) => {
      return { vehicleModel: value, step: state.step + 1 };
    }),
  vehicleTrim: null,
  SetVehicleTrim: (value) =>
    set((state) => {
      return { vehicleTrim: value, step: state.step + 1 };
    }),

  vehicleType: null,
  SetVehicleType: (value) =>
    set((state) => {
      return { vehicleType: value, step: state.step + 1 };
    }),

  vehicleSize: null,
  SetVehicleSize: (value) =>
    set((state) => {
      return { vehicleSize: value };
    }),
}));
